/// <reference path="../../../Types/index.d.ts" />
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// MUI
import { styled } from "@mui/material/styles";
import {
  Typography,
  CardContent,
  Collapse,
  CardActions,
  Grid,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components
import AddInfoForm from "./AddInfoForm";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AddInformationCollapse: React.FC = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <CardActions disableSpacing>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingLeft: "8px" }}
        >
          <Grid item xs={10}>
            <Typography variant="subtitle1" color="textSecondary" align="left">
              {t("Please provide additional information")} {"*"}
            </Typography>
          </Grid>
          <Grid item>
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <AddInfoForm />
        </CardContent>
      </Collapse>
    </>
  );
};

export default AddInformationCollapse;
