import React, { useState, useEffect, useContext, createContext } from "react";
import { useTranslation } from "react-i18next";

export const COMMERCIAL_PRODUCT_NAME = "commercial_product_name";
export const excludedAttributes = [COMMERCIAL_PRODUCT_NAME];
export const defaultAttributes = ["range", "vol", "rip"];

export type Option = {
  value: string;
  label: string;
};

export type KeyValuePair = {
  key: string;
  value: string;
};

export type AutoCompleteData = {
  commercial_product_name: string;
  attributes: { title: string; options: Option[] }[];
};

export const isAdditionalInfoComplete = (
  additionalInfo: KeyValuePair[] | undefined
) => {
  if (additionalInfo === undefined) return false;
  return (
    additionalInfo?.find((pair) => pair.key === "commercial_product_name") !==
    undefined
  );
};

export const useTranslatedLabel = () => {
  const { t } = useTranslation();
  const translations = new Map<string, string>();
  translations.set(COMMERCIAL_PRODUCT_NAME, t("COMMERCIAL PRODUCT NAME"));
  translations.set("range", t("RANGE"));
  translations.set("vol", t("VOL"));
  translations.set("rip", t("RIP"));

  const translateLabel = (label: string) => translations.get(label) || label;
  return translateLabel;
};

const mapToOptions: (additionalInfo: any) => AutoCompleteData = (
  additionalInfo
) => {
  return {
    commercial_product_name: additionalInfo.commercial_product_name,

    attributes: Object.keys(additionalInfo)
      .filter((key: string) => !excludedAttributes.includes(key))
      .map((key: string) => ({
        title: key,
        options: additionalInfo[key] as Option[],
      })),
  };
};

interface AdditionalInfoStore {
  values: KeyValuePair[];
  setValue: (pair: KeyValuePair) => void;
  resetValues: (productUUID: string) => void;
}

const AdditionalInfoStoreContext = createContext<AdditionalInfoStore>({
  values: [],
  setValue: (pair) => {},
  resetValues: () => {},
});

export const AdditionalInfoStoreContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  // state for the values of all filters
  const [values, setValues] = useState<KeyValuePair[]>([]);

  const setValue = (pair: KeyValuePair) => {
    setValues([...values.filter((value) => value.key !== pair.key), pair]);
  };

  const resetValues = () => setValues([]);

  const value = {
    values,
    setValue,
    resetValues,
  };

  return (
    <AdditionalInfoStoreContext.Provider value={value}>
      {children}
    </AdditionalInfoStoreContext.Provider>
  );
};

export const useAdditionalInfoStoreContext = (): AdditionalInfoStore => {
  return useContext(AdditionalInfoStoreContext);
};

type AdditionalInfoHook = () => {
  autoCompleteData: AutoCompleteData;
  setValue: (value: KeyValuePair) => void;
  values: KeyValuePair[];
};

export const useAdditionalInfo: AdditionalInfoHook = () => {
  const { values, setValue, resetValues } = useContext(
    AdditionalInfoStoreContext
  );

  const [autoCompleteData, setAutoCompleteData] = useState<AutoCompleteData>({
    commercial_product_name: "",
    attributes: [],
  });

  useEffect(() => {
    // declare the data fetching function
    const fetchAdditionalInfo = async () => {
      const params = new URLSearchParams();
      values.forEach((option) => params.append(option.key, option.value));

      const response = await fetch(
        `/custom/additionalinfo/?${params.toString()}`
      );
      if (!response.ok)
        throw new Error(`Network response was not ok: ${response.statusText}`);
      if (typeof response !== "object") {
        throw new Error("Response is not valid JSON");
      }

      const data = await response.json();
      if (typeof response !== "object") {
        throw new Error("Response is not valid JSON");
      }
      const autoCompleteData = mapToOptions(data);

      setAutoCompleteData(autoCompleteData);
      if (
        autoCompleteData.commercial_product_name &&
        !values.find(
          (pair) =>
            pair.key === COMMERCIAL_PRODUCT_NAME &&
            pair.value === autoCompleteData.commercial_product_name
        )
      )
        setValue({
          key: "commercial_product_name",
          value: autoCompleteData.commercial_product_name,
        });
    };

    fetchAdditionalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return {
    autoCompleteData,
    setValue,
    values,
    resetValues,
  };
};
