/// <reference path="../../../Types/index.d.ts" />
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Button,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
//Query
import { useMutation, useQuery } from "@apollo/client";
// Components
import { Counter } from "../components/Counter";
import { ProductName } from "./ProductName";
import { ProductDescription } from "./ProductDescription";
import AddInformationCollapse from "./AddInformationCollapse";
import { Label } from "../../../Components/Product/ProductLabel";
//Utils
import {
  getPriceByQuantity,
  formatValue,
  getMinQuantity,
} from "../../../Utils/markingProduct";
import { getAssetByUsage } from "../../../Utils/markingProduct";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { FragmentType, getFragmentData } from "../../../gql/fragment-masking";
import { markingProductFragment, markableFragment } from "../pages/ProductList";
import { UpdateCartMutation } from "./Content";
import { pricingRulesFragment } from "../pages/ProductList";
import { useFlag } from "../../../orderingFlags";
import { CartItemsQuery } from "../../ShoppingCart/ShoppingCart";
import {
  isAdditionalInfoComplete,
  useAdditionalInfoStoreContext,
} from "./useAdditionalInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      backgroundColor: theme.palette.secondary.main,
    },

    headerContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.secondary.contrastText,
    },
    media: {
      height: 140,
      backgroundSize: "contain",
      margin: theme.spacing(2),
    },
  })
);

export const ProductCard = (props: {
  markingProduct: FragmentType<typeof markingProductFragment>;
  markable: FragmentType<typeof markableFragment> | undefined;
}) => {
  const { t, i18n } = useTranslation();
  // Reading fragment data
  const markingProduct = getFragmentData(
    markingProductFragment,
    props.markingProduct
  );

  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();
  const [quantity, setQuantity] = useState<number>(
    markingProduct.quantityIncrement > 0 ? 0 : 1
  );
  const { values: additionalInfo, resetValues: resetAdditionalInfo } =
    useAdditionalInfoStoreContext();

  const hidePrices = useFlag(["hidePrices"]);
  const hasAdditionalInfo = useFlag(["hasAdditionalInfo"]) ?? false;
  const { data: items } = useQuery(CartItemsQuery);
  const markable = getFragmentData(markableFragment, props.markable);

  const pricingRulesItems = getFragmentData(
    pricingRulesFragment,
    markingProduct.pricingRules
  );

  const minQuantity = getMinQuantity(pricingRulesItems);

  const number_of_labels = formatValue("number", markingProduct.labelsPerUnit);

  const updateQuantity = (props: { target: { value: string } }) => {
    setQuantity(
      isNaN(parseInt(props.target.value))
        ? parseInt("")
        : parseInt(props.target.value)
    );
  };

  const [cartUpdate, { error }] = useMutation(UpdateCartMutation);

  if (error) throw error;

  const add = async () => {
    const addInfoParams = additionalInfo
      ? JSON.stringify(
          Object.fromEntries(
            additionalInfo.reduce((params, info) => {
              params.set(info.key, info.value);
              return params;
            }, new Map<string, string>())
          )
        )
      : null;

    const orderPosition = {
      markingProductID: markingProduct.uuid,
      quantity: quantity,
      markable: markable && markable.uuid,
      additionalInfo: addInfoParams,
    };

    // In case of total, quantityIncrement is set to zero
    // To make sure that the same marking product is not added again.
    const isDuplicate = items?.cartItems.some(
      (item: any) =>
        item.additionalInfo === orderPosition.additionalInfo &&
        item.markingProduct.uuid === orderPosition.markingProductID &&
        item.markable === orderPosition.markable
    );
    if (markingProduct.quantityIncrement === 0 && isDuplicate) {
      enqueueSnackbar(
        t("Same product already been added to your shopping cart"),
        {
          variant: "warning",
        }
      );
    } else {
      cartUpdate({ variables: { cartItem: orderPosition } });
      enqueueSnackbar(t("Successfully added to your shopping cart"), {
        variant: "success",
      });
      setQuantity(markingProduct.quantityIncrement > 0 ? 0 : 1);
      resetAdditionalInfo(markingProduct.uuid);
    }
  };

  const price = formatValue(
    "currency",
    getPriceByQuantity(
      pricingRulesItems,
      quantity,
      markingProduct.labelsPerUnit
    ).price
  );

  const disabled =
    markingProduct.markingProductType === ProductType.Physical
      ? quantity < minQuantity || isNaN(quantity)
      : quantity < minQuantity ||
        isNaN(quantity) ||
        (hasAdditionalInfo && !isAdditionalInfoComplete(additionalInfo));

  return (
    <>
      <Card>
        <Link
          to={{
            pathname: `/storefront/products/${markingProduct.uuid}`,
            state: { markable: markable },
          }}
          style={{ textDecoration: "none", color: "unset" }}
        >
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={getAssetByUsage(
                markingProduct.mediaAssets.edges,
                "THUMBNAIL",
                i18n.language
              )}
            />
            <CardContent>
              <ProductName markingProduct={props.markingProduct} />
              <Typography component="h6" variant="h6" align="left">
                {markingProduct.nart}
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={hidePrices ? 12 : 6}>
                  {markingProduct.markingProductType ===
                    ProductType.Digital && (
                    <ProductDescription markingProduct={props.markingProduct} />
                  )}

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="left"
                  >
                    {markingProduct.markingProductType !==
                      ProductType.Digital &&
                      `${markingProduct.labelWidth} x ${markingProduct.labelLength} mm`}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="left"
                  >
                    {markingProduct.markingProductType !==
                      ProductType.Digital && `${number_of_labels}`}

                    {markingProduct.markingProductType !==
                      ProductType.Digital && t(" labels / roll")}
                  </Typography>
                </Grid>

                {!hidePrices && (
                  <Grid item xs={6}>
                    <Typography component="div">
                      <Typography component="h5" variant="h5" display="inline">
                        {price}
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        <Label markingProduct={props.markingProduct} />
                      </Typography>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Link>

        <Grid item xs={12} style={{ padding: "16px" }}>
          <Counter
            minOrderQuantity={markingProduct.minOrderQuantity}
            quantityIncrement={markingProduct.quantityIncrement}
            markingProductType={markingProduct.markingProductType}
            value={quantity}
            labelsPerUnit={markingProduct.labelsPerUnit}
            onChange={updateQuantity}
          />
          {quantity > 1 &&
            quantity < markingProduct.minOrderQuantity &&
            markingProduct.markingProductType === ProductType.Digital && (
              <Typography color="error">
                {t("Please enter minimum of {{codes}} codes", {
                  codes: new Intl.NumberFormat(i18n.language).format(
                    markingProduct.minOrderQuantity
                  ),
                })}
              </Typography>
            )}
        </Grid>

        {hasAdditionalInfo && markingProduct.markingProductType === ProductType.Digital && (
          <Grid item xs={12}>
            <AddInformationCollapse />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            fullWidth
            style={{ borderTopLeftRadius: "0", borderTopRightRadius: "0" }}
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={add}
          >
            {t("Add")}
            <ShoppingCartIcon />
          </Button>
        </Grid>
      </Card>
    </>
  );
};
