/// <reference path="../../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { Redirect } from "react-router-dom";
// Material-UI
import { Typography, Grid } from "@mui/material";
// Components
import { InfoBar } from "../../../Components/InfoBar";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { Content } from "../components/Content";
import { SKUTag } from "../components/SKUTag";
// Query
import { useQuery } from "@apollo/client";
import { FragmentType, getFragmentData, graphql } from "../../../gql";
import { markingProductFragment, markableFragment } from "./ProductList";
import { AdditionalInfoStoreContextProvider } from "../components/useAdditionalInfo";

interface params {
  id: string;
}

interface Props {
  location: {
    state: {
      markable: FragmentType<typeof markableFragment>;
    };
  };
}

export const NartHeader = ({ nart }: { nart: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" style={{ marginRight: "8px" }}>
        {t("ARTICLE")}
      </Typography>
      <Typography color="textSecondary" variant="subtitle1">
        {nart}
      </Typography>
    </>
  );
};

export const MarkingProductQuery = graphql(/* GraphQL */ `
  query markingProduct($uuid: UUID!) {
    markingProduct(uuid: $uuid) {
      ...MarkingProduct
    }
  }
`);

export const ProductDetail = (props: Props) => {
  const { t } = useTranslation();
  const { match } = useReactRouter();
  const uuid = (match.params as params).id;
  const markable = props.location?.state?.markable;

  const { loading, error, data } = useQuery(MarkingProductQuery, {
    variables: { uuid: uuid },
  });

  const markingProductItem = getFragmentData(
    markingProductFragment,
    data?.markingProduct
  );
  const markableItem = getFragmentData(markableFragment, markable);

  if (error) throw error;
  if (loading) return <LoadingIndicator />;

  if (!data?.markingProduct) {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("There are no products available!")}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <InfoBar
        left={[
          <NartHeader
            nart={markingProductItem ? markingProductItem.nart : "N/A"}
            key={0}
          />,
        ]}
        right={[
          <SKUTag
            markable={
              markableItem ? (
                markableItem.name
              ) : (
                <Redirect
                  from="/storefront/products/:id?"
                  to="/storefront/products"
                />
              )
            }
            key={0}
          />,
        ]}
        withBottomMargin
      />

      {data?.markingProduct && (
        <AdditionalInfoStoreContextProvider>
          <Content markingProduct={data?.markingProduct} markable={markable} />
        </AdditionalInfoStoreContextProvider>
      )}
    </>
  );
};
