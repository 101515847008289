import createFlags from "flag";

export type Country = {
  code: string;
  name: string;
};

export type OrderingFlags = {
  SSOButtonType?: string;
  requestReference?: boolean;
  allowedRegister: boolean;
  minShippingDate?: string;
  minimumOrder: boolean;
  hidePrices?: boolean;
  hasAdditionalInfo?: boolean;
  hideCancelOrder?: boolean;
  hidePasswordReset?: boolean;
  hideAddresses?: boolean;
  volumeControl: {
    enabled: boolean;
  };
  allowedCountries: Country[];
  footers: {
    imprint: { markdown: boolean; links: Links | null };
    contact: { markdown: boolean; links: Links | null };
    accessibility?: { markdown: boolean; links: Links | null };
    privacypolicy: { markdown: boolean; links: Links | null };
  };
  companyProfile: {
    userPanel: boolean;
    nonEditableAddressCategories: string[];
  };
};

export type Links = {
  en: string;
  [key: string]: string;
};

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags<OrderingFlags>();

export { FlagsProvider, Flag, useFlag, useFlags };
