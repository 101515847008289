import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
// MUI
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMutation } from "@apollo/client";
import { graphql } from "../../../gql";
import { useSnackbar } from "notistack";
import { FeedbackType } from "../../../gql/graphql";
import { defaultAttributes, useTranslatedLabel } from "./useAdditionalInfo";

type ReferenceDialogProps = {
  open: boolean;
  data: any[];
  handleClose: () => void;
};

const sendFeedbackMutationDocument = graphql(/* GraphQL */ `
  mutation sendFeedback($input: FeedbackMutationInput!) {
    sendFeedback(input: $input) {
      uuid
      errors
    }
  }
`);

const ReferenceDialog = ({ open, data, handleClose }: ReferenceDialogProps) => {
  const { t } = useTranslation();
  const translateLabel = useTranslatedLabel();

  const { enqueueSnackbar } = useSnackbar();
  const [sendFeedback] = useMutation(sendFeedbackMutationDocument);

  const initialValues = defaultAttributes.reduce((acc, attribute) => {
    acc[attribute] = data.find((pair) => pair.key === attribute)?.value || "";
    return acc;
  }, {} as { [key: string]: string });

  const validationSchema = Yup.object().shape(
    defaultAttributes.reduce((acc, attr) => {
      // TODO this schema has to go into the useAdditionalInfo!!!
      if (attr === "rip") {
        acc[attr] = Yup.string()
          .matches(/^[a-zA-Z0-9]{3}$/, t("Only 3 digits or letters allowed"))
          .required(t("Required"));
      } else {
        acc[attr] = Yup.string().required(t("Required"));
      }
      return acc;
    }, {} as { [key: string]: Yup.StringSchema }) as any
  );

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    try {
      const { data } = await sendFeedback({
        variables: {
          input: {
            message: JSON.stringify(values),
            type: FeedbackType.RequestAdditionalInfo,
          },
        },
      });
      if (data?.sendFeedback?.uuid) {
        setSubmitting(false);
        enqueueSnackbar(t("Successfully sent request for a new reference."), {
          variant: "success",
        });
        resetForm();
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDialogClose = (resetForm: () => void) => {
    resetForm();
    handleClose();
  };

  /** FIXME stf/ 7/16/24
   * this never initializes the values:
   * formik immediately turns the initialValues into a useRef(initialValues)
   * and by reasons I do not really understand, this gets rid of the values.
   * I currently have no idea how to fix this: Luckily, this functionality is
   * currently not required, so it is also not crucial that it doesn't work
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("REQUEST A NEW REFERENCE")}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {Object.keys(initialValues).map((key, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <TextField
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id={key}
                    name={key}
                    label={translateLabel(key)}
                    type="text"
                    value={formik.values[key]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched[key] && Boolean(formik.errors[key])}
                    helperText={formik.touched[key] && formik.errors[key]}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box mt={2}>
            <DialogActions>
              <Button
                onClick={() => handleDialogClose(formik.resetForm)}
                color="secondary"
              >
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
              >
                {t("Submit")}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ReferenceDialog;
