import { FragmentType, getFragmentData } from "../gql";
import {
  markingProductFragment,
  markableFragment,
} from "../Modules/Storefront/pages/ProductList";
import { PricingRulesFragment } from "../gql/graphql";
import {
  orderFragment,
  orderPositionFragment,
} from "../Modules/History/pages/OrderDetail";

interface formatValue {
  (formatStyle: "currency" | "number", value: number): string;
}

export const formatValue: formatValue = (formatStyle, value) => {
  const locals = localStorage.i18nextLng;
  const currency = process.env.REACT_APP_CURRENCY
    ? process.env.REACT_APP_CURRENCY
    : "EUR";

  if (formatStyle === "currency") {
    return new Intl.NumberFormat(locals, {
      style: formatStyle,
      currency: currency,
    }).format(value);
  } else {
    return new Intl.NumberFormat(locals).format(value);
  }
};

export const getAssetByUsage = (
  assets: any[], //FragmentType<typeof MediaAssetNode>[],
  usage: string,
  language?: string | undefined
): string => {
  const asset = assets
    ? assets.find(
        (asset: any) => asset!.node !== null && asset!.node.usage === usage
        //&& language?.includes(asset.language ? asset?.language.toLowerCase() : "")
      )
    : null;
  return asset?.node && asset.node.image.src
    ? asset.node.image.src
    : "https://via.placeholder.com/300x150/?text=Image%20Not%20Found";
};

export const getMinQuantity = (
  pricingRules: readonly PricingRulesFragment[]
) => {
  return [...pricingRules].sort((a, b) => a.minQuantity - b.minQuantity)[0]
    .minQuantity;
};

export const getPriceByQuantity = (
  pricingRules: readonly PricingRulesFragment[],
  quantity: number,
  labelsPerUnit: number
) => {
  const sortedRules = [...pricingRules].sort(
    (a, b) => b.minQuantity - a.minQuantity
  );

  for (const rule of sortedRules) {
    const { minQuantity } = rule;
    const effectiveQuantity = quantity * labelsPerUnit;

    if (effectiveQuantity >= minQuantity) {
      return rule;
    }
  }

  return sortedRules[sortedRules.length - 1];
};

interface groupProduct {
  productGroup: string;
  markingProducts: FragmentType<typeof markingProductFragment>[];
}

interface IGroup {
  [key: string]: FragmentType<typeof markingProductFragment>[];
}

export const getGroupProducts = (
  markingProducts: FragmentType<typeof markingProductFragment>[],
  markingProductType?: string
) => {
  let products = getFragmentData(markingProductFragment, markingProducts);

  if (markingProductType) {
    products = products.filter(
      (obj) => obj.markingProductType === markingProductType
    );
  }

  const groups = products.reduce((acc: IGroup, item: any) => {
    item.markables.edges.forEach((obj: any) => {
      const markable = getFragmentData(markableFragment, obj?.node);
      if (!acc[markable!.name]) {
        acc[markable!.name] = [];
      }
      acc[markable!.name].push(item);
    });
    return acc;
  }, {});

  const groupNames = Object.keys(groups);
  let result: groupProduct[] = [];
  groupNames.forEach((name) => {
    result.push({ productGroup: name, markingProducts: groups[name] });
  });

  return result;
};

export const getMPByMarkable = (
  markingProducts: FragmentType<typeof markingProductFragment>[],
  value: string
) => {
  const filteredArray = markingProducts
    .filter((product) => {
      const markingProduct = getFragmentData(markingProductFragment, product);
      return markingProduct.markables.edges.some((productGroup) => {
        const markable = getFragmentData(markableFragment, productGroup?.node);
        return markable?.name === value;
      });
    })
    .map((product) => {
      const markingProduct = getFragmentData(markingProductFragment, product);
      let item = Object.assign({}, product, {
        productGroups: markingProduct.markables.edges.filter((productGroup) => {
          const markable = getFragmentData(
            markableFragment,
            productGroup?.node
          );
          return markable?.name === value;
        }),
      });
      return item;
    });

  return filteredArray;
};

export const getMPTypes = (order: FragmentType<typeof orderFragment>) => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);

  const types = Array.from(
    new Set(
      orderData.orderPositions.edges.map((item) => {
        const position = getFragmentData(orderPositionFragment, item?.node);
        const product = getFragmentData(
          markingProductFragment,
          position?.markingProduct
        );
        if (!product) throw Error;
        return product?.markingProductType;
      })
    )
  );

  return types;
};

export const getTotalLabelsPerType = (
  order: FragmentType<typeof orderFragment>,
  type?: string
): string => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);

  const totalLabels = orderData.orderPositions.edges
    .filter((item) => {
      const position = getFragmentData(orderPositionFragment, item?.node);
      const product = getFragmentData(
        markingProductFragment,
        position!.markingProduct
      );
      return product.markingProductType === type;
    })
    .reduce((current, position) => {
      const orderPosition = getFragmentData(
        orderPositionFragment,
        position?.node
      );
      return current + orderPosition!.nrOfOrderedLabels;
    }, 0);

  return totalLabels.toLocaleString();
};

export const getTotalQuantityPerType = (
  order: FragmentType<typeof orderFragment>,
  type?: string
) => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);

  const totalQuantity = orderData.orderPositions.edges
    .filter((item) => {
      const position = getFragmentData(orderPositionFragment, item?.node);
      const product = getFragmentData(
        markingProductFragment,
        position!.markingProduct
      );
      return product.markingProductType === type;
    })
    .reduce((current, position) => {
      const orderPosition = getFragmentData(
        orderPositionFragment,
        position?.node
      );
      return current + orderPosition!.quantity;
    }, 0);

  return totalQuantity;
};

export const getTotalLabels = (order: any): string => {
  const summedUplabels = order.orderPositions.edges.reduce(
    (current: any, position: any) => current + position.node.nrOfOrderedLabels,
    0
  );

  return summedUplabels.toLocaleString();
};

export const getProductGroups = (
  markingProducts: FragmentType<typeof markingProductFragment>[],
  markingProductType: string
) => {
  const MPs = markingProducts.filter((obj) => {
    const product = getFragmentData(markingProductFragment, obj);
    return product.markingProductType === markingProductType;
  });
  const productByGroups = getGroupProducts(MPs);
  const productGroups = productByGroups.map((item) => item.productGroup);
  return productGroups;
};

export const fetchUniqueMarkingProductTypes = (
  products: FragmentType<typeof markingProductFragment>[]
) => {
  const productList = getFragmentData(markingProductFragment, products);

  const uniqueMarkingProductTypes = Array.from(
    new Set(productList.map((item) => item.markingProductType))
  );
  return uniqueMarkingProductTypes;
};
