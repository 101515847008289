/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

const documents = {
    "\n  query PrivacyPolicy {\n    allContents(contentType: PRIVACY_POLICY) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n": types.PrivacyPolicyDocument,
    "\n  query Imprints {\n    allContents(contentType: IMPRINT) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n": types.ImprintsDocument,
    "\n  fragment PricingRules on PricingRuleNode {\n    price\n    minQuantity\n    scaleBasis\n  }\n": types.PricingRulesFragmentDoc,
    "\n  query getCompanyById($uuid: UUID!) {\n    company(uuid: $uuid) {\n      uuid\n      name\n      identifier\n      vat\n      canOrder\n    }\n  }\n": types.GetCompanyByIdDocument,
    "\n  mutation approveOrder($input: OrderApprovalMutationInput!) {\n    approveOrRejectOrder(input: $input) {\n      success\n      errors\n    }\n  }\n": types.ApproveOrderDocument,
    "\n  mutation changePassword($input: ChangePasswordMutationInput!) {\n    changePassword(input: $input) {\n      success\n      errors\n    }\n  }\n": types.ChangePasswordDocument,
    "\n  mutation requestPassword($input: RequestPasswordResetEmailMutationInput!) {\n    requestPasswordResetEmail(input: $input) {\n      success\n      errors\n    }\n  }\n": types.RequestPasswordDocument,
    "\n  mutation userLogin($input: LoginMutationInput!) {\n    userLogin(input: $input) {\n      success\n      errors\n      permissions\n    }\n  }\n": types.UserLoginDocument,
    "\n  mutation userLogout($input: LogoutMutationInput!) {\n    userLogout(input: $input) {\n      success\n      errors\n    }\n  }\n": types.UserLogoutDocument,
    "\n  mutation setNewPassword($input: SetNewPasswordMutationInput!) {\n    setNewPassword(input: $input) {\n      success\n      errors\n    }\n  }\n": types.SetNewPasswordDocument,
    "\n  query checkToken($tokenCheck: TokenCheckInput!) {\n    checkToken(tokenCheck: $tokenCheck) {\n      success\n      errors\n    }\n  }\n": types.CheckTokenDocument,
    "\n  query getAddress($uuid: UUID!) {\n    address(uuid: $uuid) {\n      uuid\n      companyName\n      streetAddress\n      postalCode\n      city\n      countryCode\n      addressDetails\n    }\n  }\n": types.GetAddressDocument,
    "\n  mutation placeOrder($input: PlaceOrderMutationInput!) {\n    placeOrder(input: $input) {\n      uuid\n      errors\n    }\n  }\n": types.PlaceOrderDocument,
    "\n  query myCompany {\n    myCompany {\n      ...CompanyInfo\n    }\n  }\n": types.MyCompanyDocument,
    "\n  query userAuth {\n    userLogin {\n      username\n      permissions\n    }\n  }\n": types.UserAuthDocument,
    "\n  fragment CompanyInfo on CompanyNode {\n    id\n    uuid\n    name\n    identifier\n    vat\n    canOrder\n    state\n    addresses {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    contacts {\n      edges {\n        node {\n          ...Contact\n        }\n      }\n    }\n    billingAddresses: addresses(metas_Category: BILLING) {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    deliveryAddresses: addresses(metas_Category: DELIVERY) {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    deliveryNotes: contacts(metas_Category: DELIVERY) {\n      edges {\n        node {\n          name\n          phone\n          email\n        }\n      }\n    }\n    invoicing: contacts(metas_Category: BILLING) {\n      edges {\n        node {\n          name\n          phone\n          email\n        }\n      }\n    }\n    transportationMethods\n  }\n": types.CompanyInfoFragmentDoc,
    "\n  fragment Address on AddressNode {\n    id\n    uuid\n    streetAddress\n    postalCode\n    city\n    countryCode\n    metas {\n      category\n      primary\n    }\n    addressDetails\n    deleted\n  }\n": types.AddressFragmentDoc,
    "\n  fragment Contact on ContactNode {\n    id\n    uuid\n    name\n    phone\n    email\n    metas {\n      category\n      primary\n    }\n  }\n": types.ContactFragmentDoc,
    "\n  mutation createAddress($input: CreateAddressMutationInput!) {\n    createAddress(input: $input) {\n      uuid\n      errors\n    }\n  }\n": types.CreateAddressDocument,
    "\n  mutation updateAddress($input: UpdateAddressMutationInput!) {\n    updateAddress(input: $input) {\n      uuid\n      errors\n    }\n  }\n": types.UpdateAddressDocument,
    "\n  mutation deleteAddress($input: DeleteAddressMutationInput!) {\n    deleteAddress(input: $input) {\n      success\n      errors\n    }\n  }\n": types.DeleteAddressDocument,
    "\n  mutation setPrimaryAddress($input: SetPrimaryAddressMutationInput!) {\n    setPrimaryAddress(input: $input) {\n      success\n      errors\n    }\n  }\n": types.SetPrimaryAddressDocument,
    "\n  mutation createContact($input: CreateContactMutationInput!) {\n    createContact(input: $input) {\n      uuid\n    }\n  }\n": types.CreateContactDocument,
    "\n  mutation updateContact($input: UpdateContactMutationInput!) {\n    updateContact(input: $input) {\n      uuid\n      errors\n    }\n  }\n": types.UpdateContactDocument,
    "\n  mutation deleteContact($input: DeleteContactMutationInput!) {\n    deleteContact(input: $input) {\n      success\n      errors\n    }\n  }\n": types.DeleteContactDocument,
    "\n  mutation setPrimaryContact($input: SetPrimaryContactMutationInput!) {\n    setPrimaryContact(input: $input) {\n      uuid\n      errors\n    }\n  }\n": types.SetPrimaryContactDocument,
    "\n  query countries {\n    countries {\n      code\n      name\n    }\n  }\n": types.CountriesDocument,
    "\n  mutation updateMyCompany($input: CompanyMutationInput!) {\n    updateMyCompany(input: $input) {\n      success\n      errors\n    }\n  }\n": types.UpdateMyCompanyDocument,
    "\n  query AllNews {\n    allContents(contentType: NEWS) {\n      edges {\n        node {\n          ...News\n        }\n      }\n    }\n  }\n": types.AllNewsDocument,
    "\n  fragment News on ContentNode {\n    uuid\n    contentType\n    createdAt\n    updatedAt\n    body\n    mediaAssets {\n      edges {\n        node {\n          usage\n          asset\n        }\n      }\n    }\n  }\n": types.NewsFragmentDoc,
    "\n  query getLastOrder {\n    lastOrder {\n      ...Order\n    }\n  }\n": types.GetLastOrderDocument,
    "\n  query requestLink($orderPositionUuid: UUID!) {\n    requestLink(orderPositionUuid: $orderPositionUuid) {\n      state\n      link\n      errors\n    }\n  }\n": types.RequestLinkDocument,
    "\n  mutation cancelOrder($input: RequestOrderCancelMutationInput!) {\n    requestOrderCancel(input: $input) {\n      success\n      errors\n    }\n  }\n": types.CancelOrderDocument,
    "\n  query getOrder($uuid: UUID!) {\n    order(uuid: $uuid) {\n      ...Order\n    }\n  }\n": types.GetOrderDocument,
    "\n  fragment Order on OrderNode {\n    uuid\n    orderId\n    state\n    currency\n    transportationMethod\n    handlingCosts\n    deliveryCosts\n    total\n    requestedShippingDate\n    dateCreated\n    dateModified\n    company {\n      uuid\n      inforId\n    }\n    billingAddress {\n      companyName\n      streetAddress\n      city\n      postalCode\n      countryCode\n      addressDetails\n      inforId\n      deleted\n      metas {\n        category\n        primary\n      }\n    }\n    deliveryAddress {\n      streetAddress\n      postalCode\n      city\n      countryCode\n      addressDetails\n    }\n    purchaseOrderNumber\n    orderPositions {\n      edges {\n        node {\n          ...OrderPosition\n          markable {\n            markableType\n            name\n          }\n          markingProduct {\n            markingProductType\n          }\n        }\n      }\n    }\n    subTotal\n    canCancel\n  }\n": types.OrderFragmentDoc,
    "\n  fragment OrderPosition on OrderPositionNode {\n    uuid\n    markable {\n      uuid\n      markableType\n      name\n    }\n    downloadable {\n      link\n      createdAt\n      state\n      expired\n    }\n    price\n    quantity\n    nrOfOrderedLabels\n    total\n    markingProduct {\n      ...MarkingProduct\n    }\n    additionalInfo\n  }\n": types.OrderPositionFragmentDoc,
    "\n  query allOrders($first: Int, $after: String) {\n    allOrders(orderBy: \"-dateCreated\", first: $first, after: $after) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          uuid\n          orderId\n          state\n          total\n          dateCreated\n          dateModified\n          orderPositions {\n            edges {\n              node {\n                markable {\n                  markableType\n                  name\n                }\n                nrOfOrderedLabels\n                markingProduct {\n                  markingProductType\n                }\n                additionalInfo\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.AllOrdersDocument,
    "\n  fragment OrderEdge on OrderNodeEdge {\n    node {\n      uuid\n      orderId\n      state\n      total\n      dateCreated\n      dateModified\n      orderPositions {\n        edges {\n          node {\n            markable {\n              markableType\n              name\n            }\n            nrOfOrderedLabels\n            markingProduct {\n              markingProductType\n            }\n            additionalInfo\n          }\n        }\n      }\n    }\n  }\n": types.OrderEdgeFragmentDoc,
    "\n  query InformationCenterAliases($lang: LANG!) {\n    informationCenterAliases(lang: $lang)\n  }\n": types.InformationCenterAliasesDocument,
    "\n  query AllInformationFile {\n    allContents(contentType: INFORMATION_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.AllInformationFileDocument,
    "\n  query AllInformationLink {\n    allContents(contentType: INFORMATION_LINK) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n": types.AllInformationLinkDocument,
    "\n  query BuyValigateFile {\n    allContents(contentType: BUY_VALIGATE_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.BuyValigateFileDocument,
    "\n  query BuyValigateLink {\n    allContents(contentType: BUY_VALIGATE_LINK) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.BuyValigateLinkDocument,
    "\n  mutation sendFeedback($input: FeedbackMutationInput!) {\n    sendFeedback(input: $input) {\n      uuid\n      errors\n    }\n  }\n": types.SendFeedbackDocument,
    "\n  query FAQ {\n    allContents(contentType: FAQ) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n": types.FaqDocument,
    "\n  query AllContents {\n    allContents {\n      edges {\n        node {\n          uuid\n          contentType\n        }\n      }\n    }\n  }\n": types.AllContentsDocument,
    "\n  query PilotVoucherFile {\n    allContents(contentType: RECEIVE_PILOT_VOUCHER_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.PilotVoucherFileDocument,
    "\n  query PilotVoucherLink {\n    allContents(contentType: RECEIVE_PILOT_VOUCHER_LINK) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.PilotVoucherLinkDocument,
    "\n  query HpPlugins {\n    allContents(contentType: HP_PLUGIN_DOWNLOAD_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.HpPluginsDocument,
    "\n  query PrintData {\n    allContents(contentType: PRINT_DATA_FOR_TESTING) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.PrintDataDocument,
    "\n  query Requirements {\n    allContents(contentType: REQUIREMENTS_FOR_PRINTING) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.RequirementsDocument,
    "\n  mutation voucherVolume($input: VoucherVolumeMutationInput!) {\n    voucherVolume(input: $input) {\n      success\n      errors\n    }\n  }\n": types.VoucherVolumeDocument,
    "\n  query checkVoucher($code: String!) {\n    checkVoucher(code: $code) {\n      success\n      type\n      errors\n      quota\n    }\n  }\n": types.CheckVoucherDocument,
    "\n  query checkemailexistence($email: String!) {\n    emailExistsForHp(email: $email)\n  }\n": types.CheckemailexistenceDocument,
    "\n  mutation voucherRegister($input: VoucherRegisterMutationInput!) {\n    voucherRegister(input: $input) {\n      success\n      errors\n    }\n  }\n": types.VoucherRegisterDocument,
    "\n  mutation requestRegistrationVoucher(\n    $input: RequestRegistrationVoucherMutationInput!\n  ) {\n    requestRegistrationVoucher(input: $input) {\n      code\n      errors\n    }\n  }\n": types.RequestRegistrationVoucherDocument,
    "\n  query activateUser($activationCheck: UserActivationInput!) {\n    activateUser(activationCheck: $activationCheck) {\n      success\n      errors\n    }\n  }\n": types.ActivateUserDocument,
    "\n  query cartItems {\n    cartItems @client {\n      ...CartItems\n    }\n  }\n": types.CartItemsDocument,
    "\n  fragment CartItems on ShoppingCart {\n    markingProduct {\n      uuid\n      nart\n      name\n      description\n      specification\n      markingProductType\n      customerReference\n      labelLength\n      labelWidth\n      labelsPerUnit\n      weightPerUnit\n      unitLength\n      unitWidth\n      unitHeight\n      enabled\n      pricingRules {\n        price\n        minQuantity\n        scaleBasis\n      }\n      markables {\n        edges {\n          node {\n            uuid\n            name\n            description\n          }\n        }\n      }\n    }\n    quantity\n    markable\n    additionalInfo\n  }\n": types.CartItemsFragmentDoc,
    "\n  mutation removeCartItem(\n    $markingProductID: UUID\n    $markableID: UUID\n    $additionalInfo: JSONString\n  ) {\n    removeCartItem(\n      markingProductID: $markingProductID\n      markableID: $markableID\n      additionalInfo: $additionalInfo\n    ) @client(always: true)\n  }\n": types.RemoveCartItemDocument,
    "\n  query draftOrder(\n    $positions: [OrderPositionInput!]!\n    $deliveryAddress: UUID\n    $transportationMethod: OrderOrderTransportationMethodChoices\n  ) {\n    draftOrder(\n      positions: $positions\n      deliveryAddress: $deliveryAddress\n      transportationMethod: $transportationMethod\n    ) {\n      orderPositions {\n        ...DraftOrderPosition\n      }\n      ...DraftOrder\n    }\n  }\n": types.DraftOrderDocument,
    "\n  fragment DraftOrder on DraftOrderNode {\n    deliveryCosts\n    handlingCosts\n    subTotal\n    total\n  }\n": types.DraftOrderFragmentDoc,
    "\n  fragment DraftOrderPosition on DraftOrderPositionNode {\n    markable {\n      uuid\n      name\n    }\n    markingProduct {\n      ...MarkingProduct\n    }\n    price\n    quantity\n    total\n    scaleBasis\n    currency\n    nrOfOrderedLabels\n    additionalInfo\n  }\n": types.DraftOrderPositionFragmentDoc,
    "\n  query draftVolume($positions: [OrderPositionInput!]!) {\n    draftVolume(positions: $positions) {\n      markable {\n        uuid\n        name\n        description\n      }\n      consumedQuota\n      quota\n      remainingQuota\n      status\n      nrOfOrderedLabels\n    }\n  }\n": types.DraftVolumeDocument,
    "\n  query getDashboardCardsData {\n    dashboard {\n      totalNrOfLabels\n      totalNrOfLabelsCurrentYear\n      totalNrOfRegistrations\n      totalNrOfRegistrationsCurrentYear\n    }\n  }\n": types.GetDashboardCardsDataDocument,
    "\n  query getStatisticsData {\n    dashboard {\n      monthlyVolume {\n        month\n        year\n        monthName\n        volume\n      }\n      top5Companies {\n        ...Company\n      }\n    }\n  }\n": types.GetStatisticsDataDocument,
    "\n  fragment Company on CompanyVolume {\n    companyName\n    volume\n  }\n": types.CompanyFragmentDoc,
    "\n  query getOrderingOverviewData($filters: OrderOverviewInput!) {\n    orderOverview {\n      volumeByCountry(filters: $filters) {\n        countryCode\n        countryName\n        volume\n      }\n      volumeByCategory(filters: $filters) {\n        category\n        categoryId\n        volume\n      }\n    }\n  }\n": types.GetOrderingOverviewDataDocument,
    "\n  query getOrderOverviewFilterOptions(\n    $name: String!\n    $filters: OrderOverviewInput!\n  ) {\n    orderOverview {\n      filterOptions(name: $name, filters: $filters)\n    }\n  }\n": types.GetOrderOverviewFilterOptionsDocument,
    "\n  query getOrderOverviewFilterData(\n    $filters: OrderOverviewInput!\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    orderOverview {\n      orderpositions(\n        filters: $filters\n        first: $first\n        last: $last\n        before: $before\n        after: $after\n      ) {\n        orderPositionSummary {\n          orderId\n          companyName\n          city\n          country {\n            name\n            code\n          }\n          category\n          volume\n          orderDate\n          status\n          productTypeSummary\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n          totalRows\n          totalPages\n        }\n      }\n    }\n  }\n": types.GetOrderOverviewFilterDataDocument,
    "\n  query getRegistrationsData($filters: RegistrationOverviewInput!) {\n    registrationOverview {\n      registrationsByCountry(filters: $filters) {\n        countryCode\n        countryName\n        registrations\n      }\n      registrationsByCategory(filters: $filters) {\n        category\n        categoryId\n        registrations\n      }\n    }\n  }\n": types.GetRegistrationsDataDocument,
    "\n  query getRegistrationDetails(\n    $filters: RegistrationOverviewInput!\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    registrationOverview {\n      registrationDetails(\n        filters: $filters\n        first: $first\n        last: $last\n        before: $before\n        after: $after\n      ) {\n        registrationDetailSummary {\n          companyName\n          city\n          country {\n            name\n            code\n          }\n          category\n          registration_date\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n          totalRows\n          totalPages\n        }\n      }\n    }\n  }\n": types.GetRegistrationDetailsDocument,
    "\n  query getRegistrationFilterOptions(\n    $name: String!\n    $filters: RegistrationOverviewInput!\n  ) {\n    registrationOverview {\n      filterOptions(name: $name, filters: $filters)\n    }\n  }\n": types.GetRegistrationFilterOptionsDocument,
    "\n  query brandInfo($additionalInfo: JSONString!) {\n    brandInfo(additionalInfo: $additionalInfo)\n  }\n": types.BrandInfoDocument,
    "\n  mutation updateCartItem($cartItem: ShoppingCartInput!) {\n    updateCartItem(cartItem: $cartItem) @client(always: true) {\n      markingProduct {\n        uuid\n        nart\n        name\n        description\n        specification\n        markingProductType\n        customerReference\n        labelLength\n        labelWidth\n        labelsPerUnit\n        weightPerUnit\n        unitLength\n        unitWidth\n        unitHeight\n        enabled\n        pricingRules {\n          price\n          minQuantity\n          scaleBasis\n        }\n        markables {\n          edges {\n            node {\n              uuid\n              name\n              description\n            }\n          }\n        }\n      }\n      quantity\n      markable\n      additionalInfo\n    }\n  }\n": types.UpdateCartItemDocument,
    "\n  query markingProduct($uuid: UUID!) {\n    markingProduct(uuid: $uuid) {\n      ...MarkingProduct\n    }\n  }\n": types.MarkingProductDocument,
    "\n  fragment Markable on MarkableNode {\n    uuid\n    markableType\n    name\n    description\n  }\n": types.MarkableFragmentDoc,
    "\n  fragment Image on MarkingProductImage {\n    src\n    alt\n    usage\n    height\n    width\n    language\n  }\n": types.ImageFragmentDoc,
    "\n  fragment MediaAsset on MarkingProductMediaAssetNode {\n    uuid\n    usage\n    tags\n    language\n    image {\n      ...Image\n    }\n  }\n": types.MediaAssetFragmentDoc,
    "\n  fragment MarkingProduct on MarkingProductNode {\n    uuid\n    nart\n    name\n    description\n    minOrderQuantity\n    quantityIncrement\n    specification\n    markingProductType\n    customerReference\n    labelLength\n    labelWidth\n    labelsPerUnit\n    weightPerUnit\n    unitLength\n    unitWidth\n    unitHeight\n    enabled\n    pricingRules {\n      ...PricingRules\n    }\n    markables {\n      edges {\n        node {\n          ...Markable\n        }\n      }\n    }\n    thumbnail: mediaAssets(usage: \"THUMBNAIL\") {\n      edges {\n        node {\n          ...MediaAsset\n        }\n      }\n    }\n    mediaAssets {\n      edges {\n        node {\n          ...MediaAsset\n        }\n      }\n    }\n  }\n": types.MarkingProductFragmentDoc,
    "\n  fragment MarkingProductEdge on MarkingProductNodeEdge {\n    node {\n      ...MarkingProduct\n    }\n  }\n": types.MarkingProductEdgeFragmentDoc,
    "\n  query allMarkingProducts {\n    allMarkingProducts {\n      edges {\n        ...MarkingProductEdge\n      }\n    }\n  }\n": types.AllMarkingProductsDocument,
    "\n  query allowedMarkables {\n    allowedMarkables {\n      ...Markable\n    }\n  }\n": types.AllowedMarkablesDocument,
    "\n  query LicensingEvent($markable: UUID, $perPage: Int, $pageNo: Int!) {\n    licensingEvents(markable: $markable, perPage: $perPage, pageNo: $pageNo) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      licensingEvents {\n        ...EventItem\n      }\n    }\n  }\n": types.LicensingEventDocument,
    "\n  fragment EventItem on LicensingEventNode {\n    eventType\n    amount\n    order {\n      uuid\n      orderId\n    }\n    orderPosition {\n      nrOfOrderedLabels\n      quantity\n    }\n    markable {\n      uuid\n      markableType\n      name\n      description\n    }\n    createdAt\n    companyName\n  }\n": types.EventItemFragmentDoc,
    "\n  query volumeControl($markable: UUID!) {\n    volumeControl(markable: $markable) {\n      ...VolumeControlItem\n      consumedQuota\n      quota\n    }\n  }\n": types.VolumeControlDocument,
    "\n  query volumeControls {\n    volumeControls {\n      ...VolumeControlItem\n      consumedQuota\n    }\n  }\n": types.VolumeControlsDocument,
    "\n  fragment VolumeControlItem on VolumeControlNode {\n    markable {\n      id\n      uuid\n      name\n      description\n    }\n    quota\n    consumedQuota\n    remainingQuota\n  }\n": types.VolumeControlItemFragmentDoc,
    "\n  query getAllNews {\n    allContents(contentType: NEWS) {\n      edges {\n        node {\n          ...NewsInfo\n        }\n      }\n    }\n  }\n": types.GetAllNewsDocument,
    "\n  fragment NewsInfo on ContentNode {\n    id\n    uuid\n    contentType\n    createdAt\n    updatedAt\n    body\n    mediaAssets {\n      edges {\n        node {\n          usage\n          asset\n        }\n      }\n    }\n  }\n": types.NewsInfoFragmentDoc,
};

export function graphql(source: "\n  query PrivacyPolicy {\n    allContents(contentType: PRIVACY_POLICY) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PrivacyPolicy {\n    allContents(contentType: PRIVACY_POLICY) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query Imprints {\n    allContents(contentType: IMPRINT) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Imprints {\n    allContents(contentType: IMPRINT) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment PricingRules on PricingRuleNode {\n    price\n    minQuantity\n    scaleBasis\n  }\n"): (typeof documents)["\n  fragment PricingRules on PricingRuleNode {\n    price\n    minQuantity\n    scaleBasis\n  }\n"];
export function graphql(source: "\n  query getCompanyById($uuid: UUID!) {\n    company(uuid: $uuid) {\n      uuid\n      name\n      identifier\n      vat\n      canOrder\n    }\n  }\n"): (typeof documents)["\n  query getCompanyById($uuid: UUID!) {\n    company(uuid: $uuid) {\n      uuid\n      name\n      identifier\n      vat\n      canOrder\n    }\n  }\n"];
export function graphql(source: "\n  mutation approveOrder($input: OrderApprovalMutationInput!) {\n    approveOrRejectOrder(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation approveOrder($input: OrderApprovalMutationInput!) {\n    approveOrRejectOrder(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation changePassword($input: ChangePasswordMutationInput!) {\n    changePassword(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation changePassword($input: ChangePasswordMutationInput!) {\n    changePassword(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation requestPassword($input: RequestPasswordResetEmailMutationInput!) {\n    requestPasswordResetEmail(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation requestPassword($input: RequestPasswordResetEmailMutationInput!) {\n    requestPasswordResetEmail(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation userLogin($input: LoginMutationInput!) {\n    userLogin(input: $input) {\n      success\n      errors\n      permissions\n    }\n  }\n"): (typeof documents)["\n  mutation userLogin($input: LoginMutationInput!) {\n    userLogin(input: $input) {\n      success\n      errors\n      permissions\n    }\n  }\n"];
export function graphql(source: "\n  mutation userLogout($input: LogoutMutationInput!) {\n    userLogout(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation userLogout($input: LogoutMutationInput!) {\n    userLogout(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation setNewPassword($input: SetNewPasswordMutationInput!) {\n    setNewPassword(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation setNewPassword($input: SetNewPasswordMutationInput!) {\n    setNewPassword(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query checkToken($tokenCheck: TokenCheckInput!) {\n    checkToken(tokenCheck: $tokenCheck) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  query checkToken($tokenCheck: TokenCheckInput!) {\n    checkToken(tokenCheck: $tokenCheck) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query getAddress($uuid: UUID!) {\n    address(uuid: $uuid) {\n      uuid\n      companyName\n      streetAddress\n      postalCode\n      city\n      countryCode\n      addressDetails\n    }\n  }\n"): (typeof documents)["\n  query getAddress($uuid: UUID!) {\n    address(uuid: $uuid) {\n      uuid\n      companyName\n      streetAddress\n      postalCode\n      city\n      countryCode\n      addressDetails\n    }\n  }\n"];
export function graphql(source: "\n  mutation placeOrder($input: PlaceOrderMutationInput!) {\n    placeOrder(input: $input) {\n      uuid\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation placeOrder($input: PlaceOrderMutationInput!) {\n    placeOrder(input: $input) {\n      uuid\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query myCompany {\n    myCompany {\n      ...CompanyInfo\n    }\n  }\n"): (typeof documents)["\n  query myCompany {\n    myCompany {\n      ...CompanyInfo\n    }\n  }\n"];
export function graphql(source: "\n  query userAuth {\n    userLogin {\n      username\n      permissions\n    }\n  }\n"): (typeof documents)["\n  query userAuth {\n    userLogin {\n      username\n      permissions\n    }\n  }\n"];
export function graphql(source: "\n  fragment CompanyInfo on CompanyNode {\n    id\n    uuid\n    name\n    identifier\n    vat\n    canOrder\n    state\n    addresses {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    contacts {\n      edges {\n        node {\n          ...Contact\n        }\n      }\n    }\n    billingAddresses: addresses(metas_Category: BILLING) {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    deliveryAddresses: addresses(metas_Category: DELIVERY) {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    deliveryNotes: contacts(metas_Category: DELIVERY) {\n      edges {\n        node {\n          name\n          phone\n          email\n        }\n      }\n    }\n    invoicing: contacts(metas_Category: BILLING) {\n      edges {\n        node {\n          name\n          phone\n          email\n        }\n      }\n    }\n    transportationMethods\n  }\n"): (typeof documents)["\n  fragment CompanyInfo on CompanyNode {\n    id\n    uuid\n    name\n    identifier\n    vat\n    canOrder\n    state\n    addresses {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    contacts {\n      edges {\n        node {\n          ...Contact\n        }\n      }\n    }\n    billingAddresses: addresses(metas_Category: BILLING) {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    deliveryAddresses: addresses(metas_Category: DELIVERY) {\n      edges {\n        node {\n          ...Address\n        }\n      }\n    }\n    deliveryNotes: contacts(metas_Category: DELIVERY) {\n      edges {\n        node {\n          name\n          phone\n          email\n        }\n      }\n    }\n    invoicing: contacts(metas_Category: BILLING) {\n      edges {\n        node {\n          name\n          phone\n          email\n        }\n      }\n    }\n    transportationMethods\n  }\n"];
export function graphql(source: "\n  fragment Address on AddressNode {\n    id\n    uuid\n    streetAddress\n    postalCode\n    city\n    countryCode\n    metas {\n      category\n      primary\n    }\n    addressDetails\n    deleted\n  }\n"): (typeof documents)["\n  fragment Address on AddressNode {\n    id\n    uuid\n    streetAddress\n    postalCode\n    city\n    countryCode\n    metas {\n      category\n      primary\n    }\n    addressDetails\n    deleted\n  }\n"];
export function graphql(source: "\n  fragment Contact on ContactNode {\n    id\n    uuid\n    name\n    phone\n    email\n    metas {\n      category\n      primary\n    }\n  }\n"): (typeof documents)["\n  fragment Contact on ContactNode {\n    id\n    uuid\n    name\n    phone\n    email\n    metas {\n      category\n      primary\n    }\n  }\n"];
export function graphql(source: "\n  mutation createAddress($input: CreateAddressMutationInput!) {\n    createAddress(input: $input) {\n      uuid\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation createAddress($input: CreateAddressMutationInput!) {\n    createAddress(input: $input) {\n      uuid\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation updateAddress($input: UpdateAddressMutationInput!) {\n    updateAddress(input: $input) {\n      uuid\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation updateAddress($input: UpdateAddressMutationInput!) {\n    updateAddress(input: $input) {\n      uuid\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation deleteAddress($input: DeleteAddressMutationInput!) {\n    deleteAddress(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation deleteAddress($input: DeleteAddressMutationInput!) {\n    deleteAddress(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation setPrimaryAddress($input: SetPrimaryAddressMutationInput!) {\n    setPrimaryAddress(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation setPrimaryAddress($input: SetPrimaryAddressMutationInput!) {\n    setPrimaryAddress(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation createContact($input: CreateContactMutationInput!) {\n    createContact(input: $input) {\n      uuid\n    }\n  }\n"): (typeof documents)["\n  mutation createContact($input: CreateContactMutationInput!) {\n    createContact(input: $input) {\n      uuid\n    }\n  }\n"];
export function graphql(source: "\n  mutation updateContact($input: UpdateContactMutationInput!) {\n    updateContact(input: $input) {\n      uuid\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation updateContact($input: UpdateContactMutationInput!) {\n    updateContact(input: $input) {\n      uuid\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation deleteContact($input: DeleteContactMutationInput!) {\n    deleteContact(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation deleteContact($input: DeleteContactMutationInput!) {\n    deleteContact(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation setPrimaryContact($input: SetPrimaryContactMutationInput!) {\n    setPrimaryContact(input: $input) {\n      uuid\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation setPrimaryContact($input: SetPrimaryContactMutationInput!) {\n    setPrimaryContact(input: $input) {\n      uuid\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query countries {\n    countries {\n      code\n      name\n    }\n  }\n"): (typeof documents)["\n  query countries {\n    countries {\n      code\n      name\n    }\n  }\n"];
export function graphql(source: "\n  mutation updateMyCompany($input: CompanyMutationInput!) {\n    updateMyCompany(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation updateMyCompany($input: CompanyMutationInput!) {\n    updateMyCompany(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query AllNews {\n    allContents(contentType: NEWS) {\n      edges {\n        node {\n          ...News\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllNews {\n    allContents(contentType: NEWS) {\n      edges {\n        node {\n          ...News\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment News on ContentNode {\n    uuid\n    contentType\n    createdAt\n    updatedAt\n    body\n    mediaAssets {\n      edges {\n        node {\n          usage\n          asset\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment News on ContentNode {\n    uuid\n    contentType\n    createdAt\n    updatedAt\n    body\n    mediaAssets {\n      edges {\n        node {\n          usage\n          asset\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getLastOrder {\n    lastOrder {\n      ...Order\n    }\n  }\n"): (typeof documents)["\n  query getLastOrder {\n    lastOrder {\n      ...Order\n    }\n  }\n"];
export function graphql(source: "\n  query requestLink($orderPositionUuid: UUID!) {\n    requestLink(orderPositionUuid: $orderPositionUuid) {\n      state\n      link\n      errors\n    }\n  }\n"): (typeof documents)["\n  query requestLink($orderPositionUuid: UUID!) {\n    requestLink(orderPositionUuid: $orderPositionUuid) {\n      state\n      link\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation cancelOrder($input: RequestOrderCancelMutationInput!) {\n    requestOrderCancel(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation cancelOrder($input: RequestOrderCancelMutationInput!) {\n    requestOrderCancel(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query getOrder($uuid: UUID!) {\n    order(uuid: $uuid) {\n      ...Order\n    }\n  }\n"): (typeof documents)["\n  query getOrder($uuid: UUID!) {\n    order(uuid: $uuid) {\n      ...Order\n    }\n  }\n"];
export function graphql(source: "\n  fragment Order on OrderNode {\n    uuid\n    orderId\n    state\n    currency\n    transportationMethod\n    handlingCosts\n    deliveryCosts\n    total\n    requestedShippingDate\n    dateCreated\n    dateModified\n    company {\n      uuid\n      inforId\n    }\n    billingAddress {\n      companyName\n      streetAddress\n      city\n      postalCode\n      countryCode\n      addressDetails\n      inforId\n      deleted\n      metas {\n        category\n        primary\n      }\n    }\n    deliveryAddress {\n      streetAddress\n      postalCode\n      city\n      countryCode\n      addressDetails\n    }\n    purchaseOrderNumber\n    orderPositions {\n      edges {\n        node {\n          ...OrderPosition\n          markable {\n            markableType\n            name\n          }\n          markingProduct {\n            markingProductType\n          }\n        }\n      }\n    }\n    subTotal\n    canCancel\n  }\n"): (typeof documents)["\n  fragment Order on OrderNode {\n    uuid\n    orderId\n    state\n    currency\n    transportationMethod\n    handlingCosts\n    deliveryCosts\n    total\n    requestedShippingDate\n    dateCreated\n    dateModified\n    company {\n      uuid\n      inforId\n    }\n    billingAddress {\n      companyName\n      streetAddress\n      city\n      postalCode\n      countryCode\n      addressDetails\n      inforId\n      deleted\n      metas {\n        category\n        primary\n      }\n    }\n    deliveryAddress {\n      streetAddress\n      postalCode\n      city\n      countryCode\n      addressDetails\n    }\n    purchaseOrderNumber\n    orderPositions {\n      edges {\n        node {\n          ...OrderPosition\n          markable {\n            markableType\n            name\n          }\n          markingProduct {\n            markingProductType\n          }\n        }\n      }\n    }\n    subTotal\n    canCancel\n  }\n"];
export function graphql(source: "\n  fragment OrderPosition on OrderPositionNode {\n    uuid\n    markable {\n      uuid\n      markableType\n      name\n    }\n    downloadable {\n      link\n      createdAt\n      state\n      expired\n    }\n    price\n    quantity\n    nrOfOrderedLabels\n    total\n    markingProduct {\n      ...MarkingProduct\n    }\n    additionalInfo\n  }\n"): (typeof documents)["\n  fragment OrderPosition on OrderPositionNode {\n    uuid\n    markable {\n      uuid\n      markableType\n      name\n    }\n    downloadable {\n      link\n      createdAt\n      state\n      expired\n    }\n    price\n    quantity\n    nrOfOrderedLabels\n    total\n    markingProduct {\n      ...MarkingProduct\n    }\n    additionalInfo\n  }\n"];
export function graphql(source: "\n  query allOrders($first: Int, $after: String) {\n    allOrders(orderBy: \"-dateCreated\", first: $first, after: $after) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          uuid\n          orderId\n          state\n          total\n          dateCreated\n          dateModified\n          orderPositions {\n            edges {\n              node {\n                markable {\n                  markableType\n                  name\n                }\n                nrOfOrderedLabels\n                markingProduct {\n                  markingProductType\n                }\n                additionalInfo\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query allOrders($first: Int, $after: String) {\n    allOrders(orderBy: \"-dateCreated\", first: $first, after: $after) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          uuid\n          orderId\n          state\n          total\n          dateCreated\n          dateModified\n          orderPositions {\n            edges {\n              node {\n                markable {\n                  markableType\n                  name\n                }\n                nrOfOrderedLabels\n                markingProduct {\n                  markingProductType\n                }\n                additionalInfo\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment OrderEdge on OrderNodeEdge {\n    node {\n      uuid\n      orderId\n      state\n      total\n      dateCreated\n      dateModified\n      orderPositions {\n        edges {\n          node {\n            markable {\n              markableType\n              name\n            }\n            nrOfOrderedLabels\n            markingProduct {\n              markingProductType\n            }\n            additionalInfo\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OrderEdge on OrderNodeEdge {\n    node {\n      uuid\n      orderId\n      state\n      total\n      dateCreated\n      dateModified\n      orderPositions {\n        edges {\n          node {\n            markable {\n              markableType\n              name\n            }\n            nrOfOrderedLabels\n            markingProduct {\n              markingProductType\n            }\n            additionalInfo\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query InformationCenterAliases($lang: LANG!) {\n    informationCenterAliases(lang: $lang)\n  }\n"): (typeof documents)["\n  query InformationCenterAliases($lang: LANG!) {\n    informationCenterAliases(lang: $lang)\n  }\n"];
export function graphql(source: "\n  query AllInformationFile {\n    allContents(contentType: INFORMATION_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllInformationFile {\n    allContents(contentType: INFORMATION_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query AllInformationLink {\n    allContents(contentType: INFORMATION_LINK) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllInformationLink {\n    allContents(contentType: INFORMATION_LINK) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query BuyValigateFile {\n    allContents(contentType: BUY_VALIGATE_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BuyValigateFile {\n    allContents(contentType: BUY_VALIGATE_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query BuyValigateLink {\n    allContents(contentType: BUY_VALIGATE_LINK) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BuyValigateLink {\n    allContents(contentType: BUY_VALIGATE_LINK) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation sendFeedback($input: FeedbackMutationInput!) {\n    sendFeedback(input: $input) {\n      uuid\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation sendFeedback($input: FeedbackMutationInput!) {\n    sendFeedback(input: $input) {\n      uuid\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query FAQ {\n    allContents(contentType: FAQ) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query FAQ {\n    allContents(contentType: FAQ) {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query AllContents {\n    allContents {\n      edges {\n        node {\n          uuid\n          contentType\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllContents {\n    allContents {\n      edges {\n        node {\n          uuid\n          contentType\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query PilotVoucherFile {\n    allContents(contentType: RECEIVE_PILOT_VOUCHER_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PilotVoucherFile {\n    allContents(contentType: RECEIVE_PILOT_VOUCHER_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query PilotVoucherLink {\n    allContents(contentType: RECEIVE_PILOT_VOUCHER_LINK) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PilotVoucherLink {\n    allContents(contentType: RECEIVE_PILOT_VOUCHER_LINK) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query HpPlugins {\n    allContents(contentType: HP_PLUGIN_DOWNLOAD_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HpPlugins {\n    allContents(contentType: HP_PLUGIN_DOWNLOAD_FILE) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query PrintData {\n    allContents(contentType: PRINT_DATA_FOR_TESTING) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PrintData {\n    allContents(contentType: PRINT_DATA_FOR_TESTING) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query Requirements {\n    allContents(contentType: REQUIREMENTS_FOR_PRINTING) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Requirements {\n    allContents(contentType: REQUIREMENTS_FOR_PRINTING) {\n      edges {\n        node {\n          body\n          mediaAssets {\n            edges {\n              node {\n                usage\n                asset\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation voucherVolume($input: VoucherVolumeMutationInput!) {\n    voucherVolume(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation voucherVolume($input: VoucherVolumeMutationInput!) {\n    voucherVolume(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query checkVoucher($code: String!) {\n    checkVoucher(code: $code) {\n      success\n      type\n      errors\n      quota\n    }\n  }\n"): (typeof documents)["\n  query checkVoucher($code: String!) {\n    checkVoucher(code: $code) {\n      success\n      type\n      errors\n      quota\n    }\n  }\n"];
export function graphql(source: "\n  query checkemailexistence($email: String!) {\n    emailExistsForHp(email: $email)\n  }\n"): (typeof documents)["\n  query checkemailexistence($email: String!) {\n    emailExistsForHp(email: $email)\n  }\n"];
export function graphql(source: "\n  mutation voucherRegister($input: VoucherRegisterMutationInput!) {\n    voucherRegister(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation voucherRegister($input: VoucherRegisterMutationInput!) {\n    voucherRegister(input: $input) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  mutation requestRegistrationVoucher(\n    $input: RequestRegistrationVoucherMutationInput!\n  ) {\n    requestRegistrationVoucher(input: $input) {\n      code\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation requestRegistrationVoucher(\n    $input: RequestRegistrationVoucherMutationInput!\n  ) {\n    requestRegistrationVoucher(input: $input) {\n      code\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query activateUser($activationCheck: UserActivationInput!) {\n    activateUser(activationCheck: $activationCheck) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  query activateUser($activationCheck: UserActivationInput!) {\n    activateUser(activationCheck: $activationCheck) {\n      success\n      errors\n    }\n  }\n"];
export function graphql(source: "\n  query cartItems {\n    cartItems @client {\n      ...CartItems\n    }\n  }\n"): (typeof documents)["\n  query cartItems {\n    cartItems @client {\n      ...CartItems\n    }\n  }\n"];
export function graphql(source: "\n  fragment CartItems on ShoppingCart {\n    markingProduct {\n      uuid\n      nart\n      name\n      description\n      specification\n      markingProductType\n      customerReference\n      labelLength\n      labelWidth\n      labelsPerUnit\n      weightPerUnit\n      unitLength\n      unitWidth\n      unitHeight\n      enabled\n      pricingRules {\n        price\n        minQuantity\n        scaleBasis\n      }\n      markables {\n        edges {\n          node {\n            uuid\n            name\n            description\n          }\n        }\n      }\n    }\n    quantity\n    markable\n    additionalInfo\n  }\n"): (typeof documents)["\n  fragment CartItems on ShoppingCart {\n    markingProduct {\n      uuid\n      nart\n      name\n      description\n      specification\n      markingProductType\n      customerReference\n      labelLength\n      labelWidth\n      labelsPerUnit\n      weightPerUnit\n      unitLength\n      unitWidth\n      unitHeight\n      enabled\n      pricingRules {\n        price\n        minQuantity\n        scaleBasis\n      }\n      markables {\n        edges {\n          node {\n            uuid\n            name\n            description\n          }\n        }\n      }\n    }\n    quantity\n    markable\n    additionalInfo\n  }\n"];
export function graphql(source: "\n  mutation removeCartItem(\n    $markingProductID: UUID\n    $markableID: UUID\n    $additionalInfo: JSONString\n  ) {\n    removeCartItem(\n      markingProductID: $markingProductID\n      markableID: $markableID\n      additionalInfo: $additionalInfo\n    ) @client(always: true)\n  }\n"): (typeof documents)["\n  mutation removeCartItem(\n    $markingProductID: UUID\n    $markableID: UUID\n    $additionalInfo: JSONString\n  ) {\n    removeCartItem(\n      markingProductID: $markingProductID\n      markableID: $markableID\n      additionalInfo: $additionalInfo\n    ) @client(always: true)\n  }\n"];
export function graphql(source: "\n  query draftOrder(\n    $positions: [OrderPositionInput!]!\n    $deliveryAddress: UUID\n    $transportationMethod: OrderOrderTransportationMethodChoices\n  ) {\n    draftOrder(\n      positions: $positions\n      deliveryAddress: $deliveryAddress\n      transportationMethod: $transportationMethod\n    ) {\n      orderPositions {\n        ...DraftOrderPosition\n      }\n      ...DraftOrder\n    }\n  }\n"): (typeof documents)["\n  query draftOrder(\n    $positions: [OrderPositionInput!]!\n    $deliveryAddress: UUID\n    $transportationMethod: OrderOrderTransportationMethodChoices\n  ) {\n    draftOrder(\n      positions: $positions\n      deliveryAddress: $deliveryAddress\n      transportationMethod: $transportationMethod\n    ) {\n      orderPositions {\n        ...DraftOrderPosition\n      }\n      ...DraftOrder\n    }\n  }\n"];
export function graphql(source: "\n  fragment DraftOrder on DraftOrderNode {\n    deliveryCosts\n    handlingCosts\n    subTotal\n    total\n  }\n"): (typeof documents)["\n  fragment DraftOrder on DraftOrderNode {\n    deliveryCosts\n    handlingCosts\n    subTotal\n    total\n  }\n"];
export function graphql(source: "\n  fragment DraftOrderPosition on DraftOrderPositionNode {\n    markable {\n      uuid\n      name\n    }\n    markingProduct {\n      ...MarkingProduct\n    }\n    price\n    quantity\n    total\n    scaleBasis\n    currency\n    nrOfOrderedLabels\n    additionalInfo\n  }\n"): (typeof documents)["\n  fragment DraftOrderPosition on DraftOrderPositionNode {\n    markable {\n      uuid\n      name\n    }\n    markingProduct {\n      ...MarkingProduct\n    }\n    price\n    quantity\n    total\n    scaleBasis\n    currency\n    nrOfOrderedLabels\n    additionalInfo\n  }\n"];
export function graphql(source: "\n  query draftVolume($positions: [OrderPositionInput!]!) {\n    draftVolume(positions: $positions) {\n      markable {\n        uuid\n        name\n        description\n      }\n      consumedQuota\n      quota\n      remainingQuota\n      status\n      nrOfOrderedLabels\n    }\n  }\n"): (typeof documents)["\n  query draftVolume($positions: [OrderPositionInput!]!) {\n    draftVolume(positions: $positions) {\n      markable {\n        uuid\n        name\n        description\n      }\n      consumedQuota\n      quota\n      remainingQuota\n      status\n      nrOfOrderedLabels\n    }\n  }\n"];
export function graphql(source: "\n  query getDashboardCardsData {\n    dashboard {\n      totalNrOfLabels\n      totalNrOfLabelsCurrentYear\n      totalNrOfRegistrations\n      totalNrOfRegistrationsCurrentYear\n    }\n  }\n"): (typeof documents)["\n  query getDashboardCardsData {\n    dashboard {\n      totalNrOfLabels\n      totalNrOfLabelsCurrentYear\n      totalNrOfRegistrations\n      totalNrOfRegistrationsCurrentYear\n    }\n  }\n"];
export function graphql(source: "\n  query getStatisticsData {\n    dashboard {\n      monthlyVolume {\n        month\n        year\n        monthName\n        volume\n      }\n      top5Companies {\n        ...Company\n      }\n    }\n  }\n"): (typeof documents)["\n  query getStatisticsData {\n    dashboard {\n      monthlyVolume {\n        month\n        year\n        monthName\n        volume\n      }\n      top5Companies {\n        ...Company\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment Company on CompanyVolume {\n    companyName\n    volume\n  }\n"): (typeof documents)["\n  fragment Company on CompanyVolume {\n    companyName\n    volume\n  }\n"];
export function graphql(source: "\n  query getOrderingOverviewData($filters: OrderOverviewInput!) {\n    orderOverview {\n      volumeByCountry(filters: $filters) {\n        countryCode\n        countryName\n        volume\n      }\n      volumeByCategory(filters: $filters) {\n        category\n        categoryId\n        volume\n      }\n    }\n  }\n"): (typeof documents)["\n  query getOrderingOverviewData($filters: OrderOverviewInput!) {\n    orderOverview {\n      volumeByCountry(filters: $filters) {\n        countryCode\n        countryName\n        volume\n      }\n      volumeByCategory(filters: $filters) {\n        category\n        categoryId\n        volume\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getOrderOverviewFilterOptions(\n    $name: String!\n    $filters: OrderOverviewInput!\n  ) {\n    orderOverview {\n      filterOptions(name: $name, filters: $filters)\n    }\n  }\n"): (typeof documents)["\n  query getOrderOverviewFilterOptions(\n    $name: String!\n    $filters: OrderOverviewInput!\n  ) {\n    orderOverview {\n      filterOptions(name: $name, filters: $filters)\n    }\n  }\n"];
export function graphql(source: "\n  query getOrderOverviewFilterData(\n    $filters: OrderOverviewInput!\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    orderOverview {\n      orderpositions(\n        filters: $filters\n        first: $first\n        last: $last\n        before: $before\n        after: $after\n      ) {\n        orderPositionSummary {\n          orderId\n          companyName\n          city\n          country {\n            name\n            code\n          }\n          category\n          volume\n          orderDate\n          status\n          productTypeSummary\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n          totalRows\n          totalPages\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getOrderOverviewFilterData(\n    $filters: OrderOverviewInput!\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    orderOverview {\n      orderpositions(\n        filters: $filters\n        first: $first\n        last: $last\n        before: $before\n        after: $after\n      ) {\n        orderPositionSummary {\n          orderId\n          companyName\n          city\n          country {\n            name\n            code\n          }\n          category\n          volume\n          orderDate\n          status\n          productTypeSummary\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n          totalRows\n          totalPages\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getRegistrationsData($filters: RegistrationOverviewInput!) {\n    registrationOverview {\n      registrationsByCountry(filters: $filters) {\n        countryCode\n        countryName\n        registrations\n      }\n      registrationsByCategory(filters: $filters) {\n        category\n        categoryId\n        registrations\n      }\n    }\n  }\n"): (typeof documents)["\n  query getRegistrationsData($filters: RegistrationOverviewInput!) {\n    registrationOverview {\n      registrationsByCountry(filters: $filters) {\n        countryCode\n        countryName\n        registrations\n      }\n      registrationsByCategory(filters: $filters) {\n        category\n        categoryId\n        registrations\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getRegistrationDetails(\n    $filters: RegistrationOverviewInput!\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    registrationOverview {\n      registrationDetails(\n        filters: $filters\n        first: $first\n        last: $last\n        before: $before\n        after: $after\n      ) {\n        registrationDetailSummary {\n          companyName\n          city\n          country {\n            name\n            code\n          }\n          category\n          registration_date\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n          totalRows\n          totalPages\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getRegistrationDetails(\n    $filters: RegistrationOverviewInput!\n    $first: Int\n    $after: String\n    $last: Int\n    $before: String\n  ) {\n    registrationOverview {\n      registrationDetails(\n        filters: $filters\n        first: $first\n        last: $last\n        before: $before\n        after: $after\n      ) {\n        registrationDetailSummary {\n          companyName\n          city\n          country {\n            name\n            code\n          }\n          category\n          registration_date\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n          totalRows\n          totalPages\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getRegistrationFilterOptions(\n    $name: String!\n    $filters: RegistrationOverviewInput!\n  ) {\n    registrationOverview {\n      filterOptions(name: $name, filters: $filters)\n    }\n  }\n"): (typeof documents)["\n  query getRegistrationFilterOptions(\n    $name: String!\n    $filters: RegistrationOverviewInput!\n  ) {\n    registrationOverview {\n      filterOptions(name: $name, filters: $filters)\n    }\n  }\n"];
export function graphql(source: "\n  query brandInfo($additionalInfo: JSONString!) {\n    brandInfo(additionalInfo: $additionalInfo)\n  }\n"): (typeof documents)["\n  query brandInfo($additionalInfo: JSONString!) {\n    brandInfo(additionalInfo: $additionalInfo)\n  }\n"];
export function graphql(source: "\n  mutation updateCartItem($cartItem: ShoppingCartInput!) {\n    updateCartItem(cartItem: $cartItem) @client(always: true) {\n      markingProduct {\n        uuid\n        nart\n        name\n        description\n        specification\n        markingProductType\n        customerReference\n        labelLength\n        labelWidth\n        labelsPerUnit\n        weightPerUnit\n        unitLength\n        unitWidth\n        unitHeight\n        enabled\n        pricingRules {\n          price\n          minQuantity\n          scaleBasis\n        }\n        markables {\n          edges {\n            node {\n              uuid\n              name\n              description\n            }\n          }\n        }\n      }\n      quantity\n      markable\n      additionalInfo\n    }\n  }\n"): (typeof documents)["\n  mutation updateCartItem($cartItem: ShoppingCartInput!) {\n    updateCartItem(cartItem: $cartItem) @client(always: true) {\n      markingProduct {\n        uuid\n        nart\n        name\n        description\n        specification\n        markingProductType\n        customerReference\n        labelLength\n        labelWidth\n        labelsPerUnit\n        weightPerUnit\n        unitLength\n        unitWidth\n        unitHeight\n        enabled\n        pricingRules {\n          price\n          minQuantity\n          scaleBasis\n        }\n        markables {\n          edges {\n            node {\n              uuid\n              name\n              description\n            }\n          }\n        }\n      }\n      quantity\n      markable\n      additionalInfo\n    }\n  }\n"];
export function graphql(source: "\n  query markingProduct($uuid: UUID!) {\n    markingProduct(uuid: $uuid) {\n      ...MarkingProduct\n    }\n  }\n"): (typeof documents)["\n  query markingProduct($uuid: UUID!) {\n    markingProduct(uuid: $uuid) {\n      ...MarkingProduct\n    }\n  }\n"];
export function graphql(source: "\n  fragment Markable on MarkableNode {\n    uuid\n    markableType\n    name\n    description\n  }\n"): (typeof documents)["\n  fragment Markable on MarkableNode {\n    uuid\n    markableType\n    name\n    description\n  }\n"];
export function graphql(source: "\n  fragment Image on MarkingProductImage {\n    src\n    alt\n    usage\n    height\n    width\n    language\n  }\n"): (typeof documents)["\n  fragment Image on MarkingProductImage {\n    src\n    alt\n    usage\n    height\n    width\n    language\n  }\n"];
export function graphql(source: "\n  fragment MediaAsset on MarkingProductMediaAssetNode {\n    uuid\n    usage\n    tags\n    language\n    image {\n      ...Image\n    }\n  }\n"): (typeof documents)["\n  fragment MediaAsset on MarkingProductMediaAssetNode {\n    uuid\n    usage\n    tags\n    language\n    image {\n      ...Image\n    }\n  }\n"];
export function graphql(source: "\n  fragment MarkingProduct on MarkingProductNode {\n    uuid\n    nart\n    name\n    description\n    minOrderQuantity\n    quantityIncrement\n    specification\n    markingProductType\n    customerReference\n    labelLength\n    labelWidth\n    labelsPerUnit\n    weightPerUnit\n    unitLength\n    unitWidth\n    unitHeight\n    enabled\n    pricingRules {\n      ...PricingRules\n    }\n    markables {\n      edges {\n        node {\n          ...Markable\n        }\n      }\n    }\n    thumbnail: mediaAssets(usage: \"THUMBNAIL\") {\n      edges {\n        node {\n          ...MediaAsset\n        }\n      }\n    }\n    mediaAssets {\n      edges {\n        node {\n          ...MediaAsset\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment MarkingProduct on MarkingProductNode {\n    uuid\n    nart\n    name\n    description\n    minOrderQuantity\n    quantityIncrement\n    specification\n    markingProductType\n    customerReference\n    labelLength\n    labelWidth\n    labelsPerUnit\n    weightPerUnit\n    unitLength\n    unitWidth\n    unitHeight\n    enabled\n    pricingRules {\n      ...PricingRules\n    }\n    markables {\n      edges {\n        node {\n          ...Markable\n        }\n      }\n    }\n    thumbnail: mediaAssets(usage: \"THUMBNAIL\") {\n      edges {\n        node {\n          ...MediaAsset\n        }\n      }\n    }\n    mediaAssets {\n      edges {\n        node {\n          ...MediaAsset\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment MarkingProductEdge on MarkingProductNodeEdge {\n    node {\n      ...MarkingProduct\n    }\n  }\n"): (typeof documents)["\n  fragment MarkingProductEdge on MarkingProductNodeEdge {\n    node {\n      ...MarkingProduct\n    }\n  }\n"];
export function graphql(source: "\n  query allMarkingProducts {\n    allMarkingProducts {\n      edges {\n        ...MarkingProductEdge\n      }\n    }\n  }\n"): (typeof documents)["\n  query allMarkingProducts {\n    allMarkingProducts {\n      edges {\n        ...MarkingProductEdge\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query allowedMarkables {\n    allowedMarkables {\n      ...Markable\n    }\n  }\n"): (typeof documents)["\n  query allowedMarkables {\n    allowedMarkables {\n      ...Markable\n    }\n  }\n"];
export function graphql(source: "\n  query LicensingEvent($markable: UUID, $perPage: Int, $pageNo: Int!) {\n    licensingEvents(markable: $markable, perPage: $perPage, pageNo: $pageNo) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      licensingEvents {\n        ...EventItem\n      }\n    }\n  }\n"): (typeof documents)["\n  query LicensingEvent($markable: UUID, $perPage: Int, $pageNo: Int!) {\n    licensingEvents(markable: $markable, perPage: $perPage, pageNo: $pageNo) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      licensingEvents {\n        ...EventItem\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment EventItem on LicensingEventNode {\n    eventType\n    amount\n    order {\n      uuid\n      orderId\n    }\n    orderPosition {\n      nrOfOrderedLabels\n      quantity\n    }\n    markable {\n      uuid\n      markableType\n      name\n      description\n    }\n    createdAt\n    companyName\n  }\n"): (typeof documents)["\n  fragment EventItem on LicensingEventNode {\n    eventType\n    amount\n    order {\n      uuid\n      orderId\n    }\n    orderPosition {\n      nrOfOrderedLabels\n      quantity\n    }\n    markable {\n      uuid\n      markableType\n      name\n      description\n    }\n    createdAt\n    companyName\n  }\n"];
export function graphql(source: "\n  query volumeControl($markable: UUID!) {\n    volumeControl(markable: $markable) {\n      ...VolumeControlItem\n      consumedQuota\n      quota\n    }\n  }\n"): (typeof documents)["\n  query volumeControl($markable: UUID!) {\n    volumeControl(markable: $markable) {\n      ...VolumeControlItem\n      consumedQuota\n      quota\n    }\n  }\n"];
export function graphql(source: "\n  query volumeControls {\n    volumeControls {\n      ...VolumeControlItem\n      consumedQuota\n    }\n  }\n"): (typeof documents)["\n  query volumeControls {\n    volumeControls {\n      ...VolumeControlItem\n      consumedQuota\n    }\n  }\n"];
export function graphql(source: "\n  fragment VolumeControlItem on VolumeControlNode {\n    markable {\n      id\n      uuid\n      name\n      description\n    }\n    quota\n    consumedQuota\n    remainingQuota\n  }\n"): (typeof documents)["\n  fragment VolumeControlItem on VolumeControlNode {\n    markable {\n      id\n      uuid\n      name\n      description\n    }\n    quota\n    consumedQuota\n    remainingQuota\n  }\n"];
export function graphql(source: "\n  query getAllNews {\n    allContents(contentType: NEWS) {\n      edges {\n        node {\n          ...NewsInfo\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAllNews {\n    allContents(contentType: NEWS) {\n      edges {\n        node {\n          ...NewsInfo\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment NewsInfo on ContentNode {\n    id\n    uuid\n    contentType\n    createdAt\n    updatedAt\n    body\n    mediaAssets {\n      edges {\n        node {\n          usage\n          asset\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment NewsInfo on ContentNode {\n    id\n    uuid\n    contentType\n    createdAt\n    updatedAt\n    body\n    mediaAssets {\n      edges {\n        node {\n          usage\n          asset\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string): unknown;
export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;