import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// Components
import { Counter } from "../components/Counter";
import { formatValue } from "../../../Utils/markingProduct";
import { ProductPreview } from "../components/ProductPreview";
import { ProductName } from "../components/ProductName";
import { ProductDescription } from "../components/ProductDescription";
import { Label } from "../../../Components/Product/ProductLabel";
import AddInformationCollapse from "./AddInformationCollapse";
// Query
import { useMutation, useQuery } from "@apollo/client";
//Utils
import {
  getPriceByQuantity,
  getMinQuantity,
} from "../../../Utils/markingProduct";
import { graphql, FragmentType, getFragmentData } from "../../../gql";

import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { markableFragment, markingProductFragment } from "../pages/ProductList";
import { pricingRulesFragment } from "../pages/ProductList";
import { useFlag } from "../../../orderingFlags";
import { CartItemsQuery } from "../../ShoppingCart/ShoppingCart";

import {
  isAdditionalInfoComplete,
  useAdditionalInfoStoreContext,
} from "./useAdditionalInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specification: {
      fontSize: "1.125em",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
  })
);
interface specificationProps {
  isDisplay: string;
  key: string;
  label: string;
  value: string;
}

export const UpdateCartMutation = graphql(/* GraphQL */ `
  mutation updateCartItem($cartItem: ShoppingCartInput!) {
    updateCartItem(cartItem: $cartItem) @client(always: true) {
      markingProduct {
        uuid
        nart
        name
        description
        specification
        markingProductType
        customerReference
        labelLength
        labelWidth
        labelsPerUnit
        weightPerUnit
        unitLength
        unitWidth
        unitHeight
        enabled
        pricingRules {
          price
          minQuantity
          scaleBasis
        }
        markables {
          edges {
            node {
              uuid
              name
              description
            }
          }
        }
      }
      quantity
      markable
      additionalInfo
    }
  }
`);

export const Content = (props: {
  markingProduct: FragmentType<typeof markingProductFragment>;
  markable: FragmentType<typeof markableFragment>;
}) => {
  const { t, i18n } = useTranslation();
  // Reading fragment data
  const markingProduct = getFragmentData(
    markingProductFragment,
    props.markingProduct
  );

  const theme = useTheme();
  const classes = useStyles(theme);
  const [quantity, setQuantity] = useState<number>(
    markingProduct.quantityIncrement > 0 ? 0 : 1
  );

  const { values: additionalInfo, resetValues: resetAdditionalInfo } =
    useAdditionalInfoStoreContext();

  const { enqueueSnackbar } = useSnackbar();
  const hidePrices = useFlag(["hidePrices"]);
  const hasAdditionalInfo = useFlag(["hasAdditionalInfo"]);
  const { data: items } = useQuery(CartItemsQuery);

  const markable = getFragmentData(markableFragment, props.markable);

  const pricingRulesItems = getFragmentData(
    pricingRulesFragment,
    markingProduct.pricingRules
  );

  const specification = JSON.parse(markingProduct.specification);
  const minQuantity = getMinQuantity(pricingRulesItems);

  const updateQuantity = (props: { target: { value: string } }) => {
    setQuantity(
      isNaN(parseInt(props.target.value))
        ? parseInt("")
        : parseInt(props.target.value)
    );
  };

  const [cartUpdate, { error }] = useMutation(UpdateCartMutation);

  if (error) throw error;

  const add = async () => {
    const addInfoParams = additionalInfo
      ? JSON.stringify(
          Object.fromEntries(
            additionalInfo.reduce((params, info) => {
              params.set(info.key, info.value);
              return params;
            }, new Map<string, string>())
          )
        )
      : null;

    const orderPosition = {
      markingProductID: markingProduct.uuid,
      quantity: quantity,
      markable: markable.uuid,
      additionalInfo: addInfoParams,
    };

    const isDuplicate = items?.cartItems.some(
      (item: any) =>
        item.additionalInfo === orderPosition.additionalInfo &&
        item.markingProduct.uuid === orderPosition.markingProductID &&
        item.markable === orderPosition.markable
    );

    // In case of total, quantityIncrement is set to zero
    // To make sure that the same marking product is not added again.
    if (markingProduct.quantityIncrement === 0 && isDuplicate) {
      enqueueSnackbar(
        t("Same product already been added to your shopping cart"),
        {
          variant: "warning",
        }
      );
    } else {
      cartUpdate({ variables: { cartItem: orderPosition } });
      enqueueSnackbar(t("Successfully added to your shopping cart"), {
        variant: "success",
      });
      setQuantity(markingProduct.quantityIncrement > 0 ? 0 : 1);
      resetAdditionalInfo(markingProduct.uuid);
    }
  };

  const price = formatValue(
    "currency",
    getPriceByQuantity(
      pricingRulesItems,
      quantity,
      markingProduct.labelsPerUnit
    ).price
  );

  const disabled =
    markingProduct.markingProductType === ProductType.Physical
      ? quantity < minQuantity || isNaN(quantity)
      : // additionalInfo is mandatory for Digital Products
        quantity < minQuantity ||
        isNaN(quantity) ||
        (hasAdditionalInfo && !isAdditionalInfoComplete(additionalInfo));

  return (
    <div
      style={{
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <ProductPreview markingProduct={props.markingProduct} />
        </Grid>

        <Grid item xs={12} md={4}>
          <ProductName markingProduct={props.markingProduct}></ProductName>

          <Typography component="h5" variant="h5" align="left">
            {markingProduct.nart}
          </Typography>

          {!hidePrices && (
            <Typography component="div">
              <Typography display="inline" component="h3" variant="h3">
                {`${price}`}
              </Typography>
              <Typography display="inline" variant="h5">
                <Label markingProduct={props.markingProduct} />
              </Typography>
            </Typography>
          )}

          <Typography variant="subtitle1" color="textSecondary" align="left">
            {markingProduct.markingProductType !== ProductType.Digital &&
              `(${formatValue("number", markingProduct.labelsPerUnit)} ${t(
                "Labels per Roll"
              )})`}
          </Typography>

          {markingProduct.markingProductType === ProductType.Digital && (
            <ProductDescription markingProduct={props.markingProduct} />
          )}

          <Typography variant="subtitle1" color="textSecondary" align="left">
            {markingProduct.markingProductType !== ProductType.Digital &&
              `${t("Size")}: ${markingProduct.labelWidth} x ${
                markingProduct.labelLength
              }
              ${t("Size")}: ${markingProduct.labelWidth} x ${
                markingProduct.labelLength
              } mm`}
          </Typography>

          <br></br>
          <Grid item xs={12} style={{ padding: "16px" }}>
            <Counter
              minOrderQuantity={markingProduct.minOrderQuantity}
              quantityIncrement={markingProduct.quantityIncrement}
              markingProductType={markingProduct.markingProductType}
              value={quantity}
              labelsPerUnit={markingProduct.labelsPerUnit}
              onChange={updateQuantity}
            />
            {quantity > 1 &&
              quantity < markingProduct.minOrderQuantity &&
              markingProduct.markingProductType === ProductType.Digital && (
                <Typography color="error">
                  {t("Please enter minimum of {{codes}} codes", {
                    codes: new Intl.NumberFormat(i18n.language).format(
                      markingProduct.minOrderQuantity
                    ),
                  })}
                </Typography>
              )}
          </Grid>
          {hasAdditionalInfo && markingProduct.markingProductType === ProductType.Digital && (
            <Grid item xs={12}>
              <AddInformationCollapse />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={add}
            >
              {t("Add")}
              <ShoppingCartIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ul className={classes.specification}>
              {markingProduct.markingProductType === ProductType.Digital
                ? specification
                    .filter(
                      (item: specificationProps) => item.isDisplay === "true"
                    )
                    .map((item: specificationProps) => {
                      return (
                        <li key={item.key}>{`${item.label}: ${item.value}`}</li>
                      );
                    })
                    .filter(
                      (item: specificationProps) => item.isDisplay === "true"
                    )
                    .map((item: specificationProps) => {
                      return (
                        <li key={item.key}>{`${item.label}: ${item.value}`}</li>
                      );
                    })
                : Object.keys(specification).map((key, index) => {
                    return (
                      <li key={index}>{`${key}: ${specification[key]}`}</li>
                    );
                  })}
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
