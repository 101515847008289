import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// MUI
import { Autocomplete, Grid, Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { useFlag } from "../../../orderingFlags";
import ReferenceDialog from "./ReferenceDialog";
import {
  COMMERCIAL_PRODUCT_NAME,
  useTranslatedLabel,
  useAdditionalInfo,
} from "./useAdditionalInfo";

const AddInfoForm: React.FC = () => {
  const { t } = useTranslation();
  const translateLabel = useTranslatedLabel();
  const [open, setOpen] = useState(false);
  const requestReference = useFlag(["requestReference"]);

  const { values, setValue, autoCompleteData } = useAdditionalInfo();

  return (
    <React.Fragment>
      <Grid container direction="column" spacing={{ xs: 2, md: 3 }}>
        {autoCompleteData?.attributes.map((attribute) => (
          <Grid item key={attribute.title}>
            <Box component="form" noValidate autoComplete="off">
              <Autocomplete
                disablePortal
                id={attribute.title}
                value={
                  attribute.options.find(
                    (option) =>
                      option.value ===
                      values.find((pair) => pair.key === attribute.title)?.value
                  ) || null
                }
                options={attribute.options}
                onChange={(_, selectedOption) =>
                  setValue({
                    key: attribute.title,
                    value: selectedOption?.value || "",
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translateLabel(attribute.title)}
                    variant="filled"
                  />
                )}
              />
            </Box>
          </Grid>
        ))}

        {requestReference && autoCompleteData?.commercial_product_name && (
          <Grid item>
            <TextField
              required
              margin="dense"
              id="commercial_product_name"
              name="commercial_product_name"
              label={translateLabel(COMMERCIAL_PRODUCT_NAME)}
              fullWidth
              variant="outlined"
              value={autoCompleteData?.commercial_product_name || ""}
              disabled
            />
          </Grid>
        )}
        {requestReference && !autoCompleteData?.commercial_product_name && (
          <Grid item>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => setOpen(true)}
              endIcon={<AddIcon />}
            >
              {t("REQUEST A NEW REFERENCE")}
            </Button>
          </Grid>
        )}
      </Grid>

      <ReferenceDialog
        open={open}
        data={values}
        handleClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default AddInfoForm;
