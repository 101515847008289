import React from "react";
import { ProductCard } from "./ProductCard";

// Material-ui
import Grid from "@mui/material/Grid";
import { FragmentType, getFragmentData } from "../../../gql/fragment-masking";

import { markingProductFragment, markableFragment } from "../pages/ProductList";
import { AdditionalInfoStoreContextProvider } from "./useAdditionalInfo";

export const Products = (props: {
  markingProducts: FragmentType<typeof markingProductFragment>[];
  markable: FragmentType<typeof markableFragment> | undefined;
}) => {
  const { markingProducts, markable } = props;

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      {markingProducts.map((markingProduct, index: number) => {
        // Reading fragment data
        const product = getFragmentData(markingProductFragment, markingProduct);
        return (
          markingProduct &&
          product.enabled && (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <AdditionalInfoStoreContextProvider>
                <ProductCard
                  markingProduct={markingProduct}
                  markable={markable}
                />
              </AdditionalInfoStoreContextProvider>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};
